import React, { createContext, useContext, useState, useCallback } from 'react'
import token from '@fuzzbet/abi/ERC20.json'
import getNodeUrl from '@src/utils/getRpcUrl'
import Web3 from 'web3'
const web3 = new Web3(getNodeUrl())
const contractAddr = process.env.REACT_APP_FUZZTOKEN

const BalanceProviderContext = {
	balance: "0",
	fuzzBalance: "0",
	fetchBalance: (account) => Promise(null),
	resetBalance: () => null
}

const BalanceContext = createContext(BalanceProviderContext)

const useBalance = () => {
	const [balance, setBalance] = useState()
	const [fuzzBalance, setFuzzBalance] = useState()

	const fetchBalance = useCallback(
		async (account) => {
			const balance = await web3.eth.getBalance(account)
			const parsedBalance = web3.utils.fromWei(balance.toString())
			const contract = new web3.eth.Contract(token, contractAddr)
			const fuzzBalance = await contract.methods.balanceOf(account).call()
			setBalance(parsedBalance)
			setFuzzBalance(web3.utils.fromWei(fuzzBalance))
		},
		[setBalance, setFuzzBalance]
	);

	const resetBalance = () => {
		setBalance(undefined)
		setFuzzBalance(undefined)
	};

	return {
		balance,
		fuzzBalance,
		fetchBalance,
		resetBalance
	};
};

export const BalanceProvider = ({ children }) => {
	const contextBalance = useBalance()

	const value = {
		...contextBalance
	}

	return <BalanceContext.Provider value={value}>{children}</BalanceContext.Provider>;
};

export const useBalances = () => {
	const context = useContext(BalanceContext);
	if (!context) {
		throw 'No provider';
	}
	return context;
};